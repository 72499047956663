import request from '@/apis'
// 顶部的导航数据
export function getCategoryList(data) {
  return request({
    url: '/main/mall/category/home',
    data
  })
}
export async function getCategoryListOptions(data) {
  function transformFilterOptions(data) {
    return data.map(item => {
      if (item.son && item.son.length) {
        return {
          value: item.guid,
          label: item.name,
          children: transformFilterOptions(item.son)
        }
      } else {
        return {
          value: item.guid,
          label: item.name
        }
      }
    })
  }
  const result = await getCategoryList(data)
  return transformFilterOptions(result)
}
export function moveCategoryOrder(data) {
  return request({
    url: '/main/mall/category/home/move_category',
    data
  })
}
export function editCategoryOrder(data) {
  return request({
    url: '/main/mall/category/home/edit_category',
    data,
    method: 'post'
  })
}
export function deleteCategory(guid) {
  return request({
    url: '/main/mall/category/home/delete_category',
    data: {
      guid
    }
  })
}

export function saveProduct(data) {
  return request({
    url: '/main/mall/actnew/home/save_activity',
    data,
    method: 'post'
  })
}

export function getProductActivity(guid) {
  return request({
    url: '/main/mall/actnew/home/get_activity_detail',
    data: {
      guid
    }
  })
}

export function getSearchProduct(data) {
  return request({
    url: '/main/mall/actnew/home/search_product',
    data
  })
}

export function getShopList() {
  return request({
    url: '/main/mall/shop/home/list_shop'
  })
}
export async function getShopListOptions() {
  const datas = await getShopList()
  return datas.map(item => {
    return {
      value: item.guid,
      label: item.name
    }
  })
}
export function saveSku(data) {
  return request({
    url: '/main/mall/sku/home/save_sku',
    data,
    method: 'post'
  })
}

export function getSku(data) {
  return request({
    url: '/main/mall/sku/home/get_sku_detail',
    data
  })
}

export function deleteSku(data) {
  return request({
    url: '/main/mall/sku/home/delete_product',
    data
  })
}

export function getProductDetail(data) {
  return request({
    url: '/main/mall/product/home/get_product_detail',
    data
  })
}

export function saveProductDetail(data) {
  return request({
    url: '/main/mall/product/home/edit_product',
    data,
    method: 'post'
  })
}

export function checkMaterialCode(code) {
  return request({
    url: '/main/mall/product/home/search_material_price',
    data: { code }
  })
}

export function checkNeedCheckMaterialCode(shop_guid) {
  return request({
    url: '/main/mall/product/home/check_shop_price',
    data: { shop_guid }
  })
}
