<template>
  <section class="xa-view xa-container">
    <titleFilterBarHead
      :isLoading="isLoading"
      :parent="parent"
      :title="title"
      :showSwitchBtn="false"
    />
    <div class="xa-flex xa-bg-white xa-container">
      <div class="xa-container" v-loading="isLoading">
        <el-form
          v-if="!isLoading"
          class="xa-form__wrap"
          ref="form"
          label-position="left"
          :rules="rules"
          :model="form"
          label-width="160px"
        >
          <el-form-item label="所属店铺" prop="shop_guid">
            <el-select v-model="form.shop_guid" placeholder="请选择">
              <el-option
                v-for="item in shopOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="所属分类" prop="categorys">
            <el-button icon="el-icon-plus" size="mini"  @click="addCategory">新增</el-button>
            <el-table :data="form.categorys" style="width: 300px"  border size="mini" max-height="400px" class="mt16">
              <el-table-column prop="date" label="产品分类">
                <template slot-scope="scope">
                  <el-cascader
                    class="xa-flex"
                    v-model="scope.row.category"
                    :props="{ checkStrictly: true }"
                    placeholder="请选择产品分类"
                    @change="selectCategoryChange(scope.row)"
                    :options="categoryListOptions"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作" width="60">
                <template slot-scope="scope">
                  <el-button @click="deleteCategory(scope.row)" type="text" size="small" v-if="scope.$index > 0">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            
          </el-form-item>
          <el-form-item label="物料编码" prop="material_code">
            <el-input
              v-loading="isCheckingCode"
              v-model="form.material_code"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-show="form.price&&needAutoFixedPrice">
            <div>
              <span class="xa-txt-secondary">物料系统定价为：</span
              ><span class="xa-color-info">{{ form.price }}￥</span>
              <p class="xa-txt-placeholder">
                该价格由物料系统定义，如有疑问，可以咨询系统管理员。
              </p>
            </div>
          </el-form-item>
          <el-form-item label="商品标题" prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="商品价格" prop="price" v-if="!needAutoFixedPrice">
            <el-input
              v-model="form.price"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="子标题" prop="sub_title">
            <el-input
              v-model="form.sub_title"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="商品库存" prop="stock">
            <el-input-number
              v-model="form.stock"
              placeholder="请输入"
              clearable
              :min="0"
            ></el-input-number>
            <span class="xa-txt-regular xa-mgl-8 xa-txt-14"
              >注：库存设置不可高于当前erp库存！</span
            >
          </el-form-item>
          <el-form-item label="商品比例" prop="stock_percent">
            <el-input-number
              v-model="form.stock_percent"
              placeholder="请输入"
              clearable
              :min="0"
              :max="100"
            ></el-input-number>
            <span class="xa-txt-regular xa-mgl-8 xa-txt-14"
              >注：商城库存与erp库存的默认比例（0-100）</span
            >
          </el-form-item>
          <hr />
          <el-form-item label="商品规格" class="params-box">
            <div
              class="params-item"
              v-for="(param, index) in editProductParams"
              :key="index"
            >
              <div class="xa-cell">
                <span class="label">规格名</span>
                <el-input
                  class="input-box"
                  v-model="param.title"
                  placeholder="请输入"
                  clearable
                />
                <el-button
                  type="text"
                  class="delete-txt"
                  style="margin-left:8px;"
                  @click="onDeleteParams(index)"
                  >删除该规格</el-button
                >
              </div>
              <div class="xa-cell" style="margin-top:16px;">
                <div class="label">规格值</div>
                <div class="xa-cell params-value">
                  <div v-for="(val, i) in param.option" :key="i">
                    <el-input
                      class="input-box"
                      :value="val"
                      placeholder="请输入"
                      @input="onChangeOption(arguments[0], index, i)"
                      clearable
                    />
                    <el-button
                      icon="el-icon-remove-outline"
                      circle
                      @click="onDeleteParamsOption(index, i)"
                    />
                  </div>
                  <el-button
                    type="text"
                    style="margin-left:8px;"
                    @click="onAddParamsValue(index)"
                    >添加规格值</el-button
                  >
                </div>
              </div>
            </div>
            <div class="add-btn">
              <el-button
                type="primary"
                size="small"
                @click="onAddNewParam"
                round
                >添加商品规格</el-button
              >
              <el-button
                v-show="editProductParams.length"
                type="primary"
                size="small"
                @click="onCheckParam"
                round
                >确定修改</el-button
              >
            </div>
            <div class="params-table" v-if="productParams.length">
              <div class="table-head xa-cell">
                <div
                  class="xa-flex"
                  v-for="head in productParams"
                  :key="head.title"
                >
                  {{ head.title }}
                </div>
                <div class="xa-flex">库存</div>
              </div>
              <div class="table-content xa-cell">
                <div
                  class="xa-view xa-flex"
                  v-for="(params, index) in productParams"
                  :key="params.title"
                >
                  <div
                    class="table-cell xa-flex xa-cell"
                    v-for="i in productLevelCount[index]"
                    :key="i"
                  >
                    {{
                      productParams[index].option[
                        (i - 1) % productParams[index].option.length
                      ]
                    }}
                  </div>
                </div>
                <div class="xa-view xa-flex">
                  <div
                    class="table-cell"
                    v-for="item in productChoiceList"
                    :key="item"
                  >
                    <el-input
                      v-model="productChoiceResult[item]"
                      placeholder="请输入"
                      size="mini"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <hr />
          <el-form-item label="商品轮播图" prop="pics">
            <UploadCom
              style="padding-top:0;"
              mode="edit"
              v-model="form.pics"
              :size="[325, 180]"
            />
            <div class="xa-txt-12 xa-color-warning">
              图片尺寸要求:750px*360px
            </div>
          </el-form-item>
          <el-form-item label="商品详情图" prop="description_pics">
            <UploadCom
              style="padding-top:0;"
              mode="edit"
              v-model="form.description_pics"
              :size="[325, 180]"
            />
            <div class="xa-txt-12 xa-color-warning">
              图片尺寸要求:750px*360px
            </div>
          </el-form-item>
          <el-form-item label="活动详情" prop="detail">
            <Ueditor v-model="form.detail" />
          </el-form-item>
          <el-form-item label="购买须知" prop="description">
            <Ueditor v-model="form.description" />
          </el-form-item>
          <el-form-item label="下架" prop="disable">
            <el-switch v-model="form.disable" />
            <span class="xa-mgl-8 xa-txt-yellow"
              >下架后该商品将无法显示给用户，请谨慎选择！</span
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div
      class="page-btn"
      style="padding:6px 32px;border-top:1px solid #e4e4e4;"
    >
      <el-button type="primary" size="mini" @click="onSubmit">保存</el-button>
      <el-button size="mini" @click="$router.go(-1)">取消</el-button>
    </div>
  </section>
</template>
<script>
import {
  getCategoryListOptions,
  getShopListOptions,
  getProductDetail,
  saveProductDetail,
  checkMaterialCode,
  checkNeedCheckMaterialCode
} from '@target/apis/mall'
import recodeLastPath from '@/controllers/recodeLastPath'
import * as UUID from 'uuidjs'
const storageKey = 'MALL_PRODUCTFORM_PARENT'
export default {
  components: {
    titleFilterBarHead: () => import('@/components/comHead/FilterBarHead'),
    UploadCom: () => import('@/components/xa-form/coms/FormUpload.vue'),
    Ueditor: () => import('@/components/ueditor')
  },
  data() {
    return {
      title: '商品详情',
      isLoading: false,
      isCheckingCode: false,
      parent: {
        // 上一个页面得到信息
        path: '',
        title: ''
      },
      categoryListOptions: [],
      shopOptions: [],
      editProductParams: [],
      productParams: [],
      productChoiceList: [],
      productChoiceResult: {},
      productLevelCount: [],
      needAutoFixedPrice: false, // 是不是需要系统标定价格
      form: {
        guid: '',
        shop_guid: '', // 所属店铺
        category: [],
        categorys: [{category: [], id: UUID.generate()}],
        category_guid: '', // 所属分类
        material_code: '', // 物料编码
        title: '', // 商品标题
        sub_title: '', // 子标题
        price: '', // 商品价格
        stock: '', // 商品库存
        stock_percent: '', // 商品价格
        first_pic: [], // 详情图
        pics: [], // 轮播图
        description_pics: [],
        detail: '',
        description: '',
        disable: false,
        params: []
      },
      rules: {
        shop_guid: [
          { required: true, message: '请选择所属店铺', trigger: 'change' },
          { validator: this.onFormNeedCheckMaterialCode, trigger: 'change' }
        ],
        categorys: [
          {
            required: true,
            message: '请选择所属分类',
            type: 'array',
            trigger: 'change'
          },
          { validator: this.categorysChange, trigger: 'change' }
        ],
        material_code: [
          { required: true, message: '请填写物料编码', trigger: 'blur' },
          { validator: this.onFormCheckMaterialCode, trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写商品标题', trigger: 'change' }
        ],
        price: [{ required: true, message: '请填写商品价格' }]
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const params = JSON.parse(JSON.stringify(this.form))
            params.category = params.categorys.filter(item => item.category.length).map(item => item.category.pop())
            delete params.categorys
            console.log('params', params)
            params.disable = params.disable ? 2 : 1
            this.isLoading = true
            params.params = this.productParams
            params.param_choice = this.productChoiceResult
            await this.$actionWithConfirm(saveProductDetail(params))
            this.$message.success('保存成功')
            this.isLoading = false
            this.$router.go(-1)
          } catch (error) {
            console.log('error', error)
            this.isLoading = false
            return error
          }
        } else {
          this.$message.error('请检查输入！')
          return false
        }
      })
    },
    onDeleteParams(index) {
      this.editProductParams.splice(index, 1)
    },
    onDeleteParamsOption(index, i) {
      this.editProductParams[index].option.splice(i, 1)
    },
    onAddParamsValue(index) {
      this.editProductParams[index].option.push('')
    },
    builLParamResult(result = this.productChoiceResult) {
      const target = this.productParams
      let names = target[0].option
      let productLevelCount = [target[0].option.length]
      for (let i = 1; i < target.length; i++) {
        let mNames = []
        productLevelCount.push(
          productLevelCount[i - 1] * target[i].option.length
        )
        names.forEach(preKey =>
          target[i].option.forEach(key => mNames.push(preKey + ',' + key))
        )
        names = mNames
      }
      let map = {}
      names.forEach(key => {
        map[key] = 0
      })
      if (result) {
        names.forEach(key => {
          if (result[key]) {
            map[key] = result[key]
          }
        })
      }
      this.productLevelCount = productLevelCount
      this.productChoiceList = names
      this.productChoiceResult = map
      return names
    },
    onCheckParam() {
      let result = true
      this.editProductParams.forEach(params => {
        result =
          params.title && params.option.find(item => item === '') === undefined
      })
      if (!result) {
        this.$message.error('规格名，规格值不允许为空！')
      } else {
        this.productParams = JSON.parse(JSON.stringify(this.editProductParams))
        this.builLParamResult()
      }
    },
    onChangeOption(txt, i, y) {
      this.editProductParams[i].option[y] = txt
      this.editProductParams = this.$utils.copy(this.editProductParams)
    },
    onAddNewParam() {
      this.editProductParams.push({ title: '', option: [''] })
    },
    /**
     * 校验
     */
    onFormNeedCheckMaterialCode(rule, value, callback) {
      window.console.log('onFormNeedCheckMaterialCode', value)
      if (value) {
        checkNeedCheckMaterialCode(value).then(data => {
          window.console.log('checkNeedCheckMaterialCode', data)
          const { status } = data
          this.needAutoFixedPrice = status
          window.console.log('checkNeedCheckMaterialCode', status)
          callback && callback()
        })
      }
    },
    onFormCheckMaterialCode(rule, value, callback) {
      if (!this.form.shop_guid) {
        this.form.material_code = ''
        this.$message.error('请先选择所属店铺')
        callback()
        return
      }
      if (value) {
        this.isCheckingCode = true
        checkMaterialCode(value)
          .then(data => {
            if (this.needAutoFixedPrice) {
              this.form.price = data.price
            }
            if (!this.form.title) { // 如果没有设置标题，采用系统的标题
              this.form.title = data.name
            }
            callback()
          })
          .catch(error => {
            this.form.price = ''
            callback(
              new Error(
                error.message || '该物料没有相关系统信息，请联系系统管理员。'
              )
            )
          }).finally(() => {
            this.isCheckingCode = false
          })
      } else {
        callback()
      }
    },
    categorysChange (rule, value, callback) {
      console.log('value', value)
      if (value.every(item => item.category.length === 0)) { // 没有任何选项的时候
        callback('请选择至少一个所属分类')
        return
      }
      callback()
    },
    deleteCategory (row) {
      this.form.categorys = this.form.categorys.filter(item => item.id !== row.id)
    },
    addCategory () {
      this.form.categorys.push({
        category: [],
        id: UUID.generate()
      })
    },
    initCategoryMap (list) {
      let obj = {}
      this.initCageforyMapHandle(obj, list)
      this.categoryParentMap = obj
    },
    initCageforyMapHandle (obj, items, parents = []) {
      console.log('items 检测是否有一致')
      items.forEach((item) => {
        obj[item.value] = parents
          if (item.children) {
          this.initCageforyMapHandle(obj, item.children, parents.concat(item.value))
        }
      })
    },
    selectCategoryChange (row) {
      console.log('row', row)
      let id = row.id
      let categoryStr = row.category.join(',')
      if (this.form.categorys.filter(item => item.id !== id).some(item => item.category.join(',') === categoryStr) ) {
        row.category = []
        this.$message.warning('请勿重复选择分类')
      }
    }

  },
  beforeRouteEnter(to, from, next) {
    /* 如果是导航点击的跳转地址 着直接打开 */
    if (to.query.nav) {
      next()
    } else {
      next(vm => {
        const parent = recodeLastPath(from, vm, storageKey)
        if (parent) vm.parent = parent
      })
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      this.categoryListOptions = await getCategoryListOptions() // 获取产品分类
      this.initCategoryMap(this.categoryListOptions)
      this.shopOptions = await getShopListOptions() // 获取店铺分类
      if (this.$route.query.params) {
        const params = JSON.parse(this.$route.query.params)
        this.isLoading = true
        const data = await getProductDetail(params) // 获取商品详情
        data.disable = parseInt(data.disable) === 2
        // 过滤已经不存在分类
        data.category_guids = data.category_guids.filter(item => this.categoryParentMap[item])
        // 同种分类过滤
        data.categorys = data.category_guids && data.category_guids.length ? data.category_guids.map(item => ({
          category: this.categoryParentMap[item].concat(item)
        })) : [{category: [data.category_guid], id: UUID.generate()}]
        this.productParams = data.params
        this.editProductParams = JSON.parse(JSON.stringify(data.params))
        data.param_choice && this.builLParamResult(data.param_choice)
        this.onFormNeedCheckMaterialCode('', data.shop_guid)
        Object.assign(this.form, data)
        this.form = {
          ...this.form, ...data
        }
        console.log('this.form', this.form)
      }
    } catch (error) {
      console.log('error', error)
      this.$message.error(error.message)
    }
    this.isLoading = false
    // this.productParams = [
    //   {
    //     'guid': 'F94F957AAE05B21716D1BEBDF3C65E31',
    //     'title': '口味',
    //     'option': [
    //       '香辣',
    //       '五香'
    //     ]
    //   },
    //   {
    //     'guid': 'E45AC53194247C7039EA21CB645B5E91',
    //     'title': '性别',
    //     'option': [
    //       '雄',
    //       '雌'
    //     ]
    //   }
    // ]
    // this.editProductParams = JSON.parse(JSON.stringify(this.productParams))
    // this.builLParamResult({
    //   '香辣,雄': 2,
    //   '五香,雄': 2,
    //   '香辣,雌': 1,
    //   '五香,雌': 1
    // })
  }
}
</script>
<style lang="scss" scoped>
.xa-form__wrap {
  padding: 32px;
  max-width: 960px;
  // height: calc(100vh - 80px);
  /deep/ .el-input {
    max-width: 217px;
  }
  hr {
    border: 0;
    height: 1px;
    background: #dcdfe6;
    margin: 16px 0;
  }
}
.params-box {
  .params-item {
    padding: 8px 4px;
    background-color: #f2f2f2;
  }
  .params-item + .params-item {
    margin-top: 16px;
  }
  .params-value {
    flex-wrap: wrap;
  }
  .delete-txt {
    color: red;
  }
  .label {
    min-width: 4em;
  }
  .input-box {
    width: 120px;
    padding: 4px 0;
    margin: 0 0.5em 0 2em;
  }
  .add-btn {
    padding: 8px 4px;
  }
  .params-table {
    border-top: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    .table-cell {
      padding: 4px;
      border-right: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
    }
  }
  .table-content {
    align-items: stretch;
  }
  .table-head {
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
  }
}
.mt16{
  margin-top: 8px;
}
</style>
