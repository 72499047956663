export default function(from, vm, sessionKey) {
  // 记录/恢复 来访页面
  if (from && from.fullPath && from.query.title) {
    const parent = {
      path: from.fullPath,
      title: from.query.title
    }
    vm.$utils.setSessionStorage(sessionKey, {
      URL: window.location.href,
      parent: parent
    })
    return parent
  } else {
    const parent = vm.$utils.getSessionStorage(sessionKey)
    if (parent && parent.URL === window.location.href) return parent.parent
  }
  return
}
